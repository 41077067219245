import { useTranslation } from 'react-i18next';

import {
  Wrapper,
  Spaghetti,
  Content,
  LeftContent,
  RightContent,
  Category,
  Title,
  Foot,
  Divider,
  FootLinks,
  LegalNotice,
  FooterLink,
  Link
} from './Footer.styled';
import spaghetti from '../../static/assets/icons/spaghetti_2.svg';

function Footer() {
  const { t } = useTranslation('lang');

  return (
    <Wrapper>
      <Spaghetti src={spaghetti} alt="Colourful curvy figure" />
      <Content>
        <LeftContent>
          <Category>Contact</Category>
          <Title>MATER LAW</Title>
          <Link
            href="mailto:contact@mater.law"
            target="_blank"
            rel="noreferrer"
            className="link__contact"
          >
            contact@mater.law
          </Link>
          <Link href="https://www.linkedin.com/company/mater-law/" target="_blank" rel="noreferrer">
            LinkedIn
          </Link>
          <div>5 rue Lincoln</div>
          <div>75008</div>
          <div>Paris</div>
        </LeftContent>
        <RightContent>
          <Category>Pages</Category>
          <FooterLink to="/expertises">{t('footer.pages.0')}</FooterLink>
          <FooterLink to="/team">{t('footer.pages.1')}</FooterLink>
          <FooterLink to="/contact">{t('footer.pages.2')}</FooterLink>
        </RightContent>
      </Content>
      <Foot>
        <Divider />
        <FootLinks>
          <div>{t('footer.copyright')}</div>
          <LegalNotice>
            <FooterLink to="/legal-notice">{t('footer.legal-notice')}</FooterLink>
            <FooterLink to="/privacy-policy">{t('footer.privacy-policy')}</FooterLink>
          </LegalNotice>
        </FootLinks>
      </Foot>
    </Wrapper>
  );
}

export default Footer;
