import Profiles from '../../components/Team/Profiles/Profiles';
import Values from '../../components/Team/Values/Values';

function Team() {
  return (
    <>
      <Profiles />
      <Values />
    </>
  );
}

export default Team;
