import styled from '@emotion/styled';
import { theme } from '../../config/theme';

type BurgerMenuProps = {
  isVisible: boolean;
};

export const Burger = styled.div`
  cursor: pointer;
`;

export const Bar = styled.div`
  background-color: ${theme.colour.white};
  height: 4px;
  margin: 6px 0;
  transition: 0.4s;
  width: 30px;
`;

export const Menu = styled.ul<BurgerMenuProps>`
  background-color: ${theme.colour.purple};
  display: ${(props) => (props.isVisible ? 'block' : 'none')};
  height: 100vh;
  left: 0;
  list-style: none;
  padding-left: 0;
  padding-top: 20px;
  position: absolute;
  top: 80px;
  width: 100%;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  animation: ${(props) => (props.isVisible ? 'fade-in 0.2s ease-in-out' : 'none')};
`;

export const MenuItem = styled.li`
  font-size: 22px;
  margin-bottom: 20px;
  text-align: center;
`;

export const MenuLink = styled.a`
  color: ${theme.colour.white};
  padding-right: 10px;
  text-decoration: none;
  text-transform: uppercase;
  transition: 300ms;

  :hover {
    color: ${theme.colour.pink};
  }
`;

export const LanguageSwitcher = styled.li`
  color: rgba(255, 255, 255, 0.6);
  display: flex;
  font-size: 22px;
  justify-content: center;
  text-decoration: none;

  div {
    cursor: pointer;
    transition: 300ms;

    :first-of-type {
      margin-right: 8px;
    }

    :hover {
      color: ${theme.colour.white};
      transition: 300ms;
    }
  }
`;
