import { useTranslation } from 'react-i18next';
import { Wrapper, Container, Box, Divider, Title, Text, Subtitle } from './Values.styled';

function Values() {
  const { t } = useTranslation('lang');

  return (
    <Wrapper>
      <Title>{t('team.values.title')}</Title>
      <Container>
        <Box>
          <Subtitle>{t('team.values.box1.title')}</Subtitle>
          <Divider />
          <Text>{t('team.values.box1.text')}</Text>
        </Box>
        <Box>
          <Subtitle>{t('team.values.box2.title')}</Subtitle>
          <Divider />
          <Text>{t('team.values.box2.text')}</Text>
        </Box>
        <Box>
          <Subtitle>{t('team.values.box3.title')}</Subtitle>
          <Divider />
          <Text>{t('team.values.box3.text')}</Text>
        </Box>
      </Container>
    </Wrapper>
  );
}

export default Values;
