import { useTranslation, Trans } from 'react-i18next';
import { Fade } from 'react-slideshow-image';
import { Wrapper, BackgroundImage, Slogan } from './Hero.styled';

const fadeImages = [
  {
    url: '/static/images/leather.jpg'
  },
  {
    url: '/static/images/concrete.png'
  },
  {
    url: '/static/images/glass.jpg'
  },
  {
    url: '/static/images/leather2.jpg'
  },
  {
    url: '/static/images/marble.png'
  },
  {
    url: '/static/images/metal.jpg'
  },
  {
    url: '/static/images/paper.jpg'
  },
  {
    url: '/static/images/plastic.jpg'
  },
  {
    url: '/static/images/textile.jpg'
  },
  {
    url: '/static/images/wood.jpg'
  }
];

function Hero() {
  const { t } = useTranslation('lang');

  return (
    <Wrapper>
      <div className="slide-container">
        <Fade arrows={false} duration={6000} pauseOnHover={false}>
          {fadeImages.map((fadeImage, index) => (
            <div key={index}>
              <BackgroundImage src={fadeImage.url} />
              <Slogan>
                <Trans>{t('home.hero.slogan')}</Trans>
              </Slogan>
            </div>
          ))}
        </Fade>
      </div>
    </Wrapper>
  );
}

export default Hero;
