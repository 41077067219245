import { createCtx } from '../AppContext';

const getCookiesFromLocalStorage = () => {
  try {
    return JSON.parse(localStorage.getItem('cookies-consent') || '');
  } catch (error) {
    return null;
  }
};

const defaultCookiesConsent = getCookiesFromLocalStorage() ?? undefined;

const configDefaultValue = {
  cookiesConsent: defaultCookiesConsent,
  userLanguage: ''
};

export const [ConfigContext, ConfigProvider] = createCtx(configDefaultValue);
