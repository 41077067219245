import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import Home from '../../pages/Home/Home';
import Expertises from '../../pages/Expertises/Expertises';
import Team from '../../pages/Team/Team';
import Juliette from '../../components/Team/Juliette/Juliette';
import Alice from '../../components/Team/Alice/Alice';
import Contact from '../../pages/Contact/Contact';
import LegalNotice from '../../pages/LegalNotice/LegalNotice';
import PrivacyPolicy from '../../pages/PrivacyPolicy/PrivacyPolicy';
import App from '../../App';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<App />}>
      <Route index path="/" element={<Home />} />
      <Route path="expertises" element={<Expertises />} />
      <Route path="team" element={<Team />} />
      <Route path="team/juliette_roquette" element={<Juliette />} />
      <Route path="team/alice_bermont_vialatte" element={<Alice />} />
      <Route path="contact" element={<Contact />} />
      <Route path="contact/test" element={<div>TEST PAGE</div>} />
      <Route path="legal-notice" element={<LegalNotice />} />
      <Route path="privacy-policy" element={<PrivacyPolicy />} />
    </Route>
  )
);
