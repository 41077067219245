import styled from '@emotion/styled';
import { theme } from '../../../config/theme';

export const Wrapper = styled.div`
  height: 100vh;
`;

export const BackgroundImage = styled.img`
  height: 100vh;
  overflow: hidden;

  ${theme.device.desktop} {
    width: 100%;
  }
`;

export const Slogan = styled.div`
  backdrop-filter: blur(10px);
  background-color: rgb(255 255 255 / 0.3);
  bottom: 60px;
  color: ${theme.colour.purple};
  font-size: 40px;
  max-width: 310px;
  padding: 40px;
  position: absolute;

  span {
    font-weight: bold;
  }

  strong {
    font-weight: bold;
  }

  ${theme.device.desktop} {
    bottom: 40px;
    font-size: 48px;
    max-width: 540px;
    padding: 40px;
  }
`;
