import { useTranslation } from 'react-i18next';
import {
  Wrapper,
  Container,
  Slogan,
  ProfileLink,
  ImageContainer,
  FirstName,
  LastName,
  Subtitle
} from './Profiles.styled';

function Profiles() {
  const { t } = useTranslation('lang');

  return (
    <Wrapper>
      <Slogan>{t('team.profiles.slogan')}</Slogan>
      <Container>
        <ProfileLink to="/team/juliette_roquette">
          <ImageContainer>
            <img
              className="juliette"
              src={'/static/images/juliette_roquette.png'}
              alt="Juliette Roquette"
            />
          </ImageContainer>
          <FirstName>Juliette</FirstName>
          <LastName>Roquette</LastName>
          <Subtitle>{t('team.profiles.juliette.subtitle.0')}</Subtitle>
          <Subtitle>{t('team.profiles.juliette.subtitle.1')}</Subtitle>
        </ProfileLink>
        <ProfileLink to="/team/alice_bermont_vialatte">
          <ImageContainer>
            <img
              className="alice"
              src={'/static/images/alice_bermont_vialatte.jpg'}
              alt="Alice Bermont-Vialatte"
            />
          </ImageContainer>
          <FirstName>Alice</FirstName>
          <LastName>Bermont-Vialatte</LastName>
          <Subtitle>{t('team.profiles.alice.subtitle.0')}</Subtitle>
          <Subtitle>{t('team.profiles.alice.subtitle.1')}</Subtitle>
        </ProfileLink>
      </Container>
    </Wrapper>
  );
}

export default Profiles;
