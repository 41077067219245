import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper, Title, Slogan, Subwrapper, List, Subtitle, ListItem } from './Compliance.styled';

interface ComplianceProps {
  id: string;
}

function Compliance({ id }: ComplianceProps) {
  const { t } = useTranslation('lang');
  const materialIndexes = [1, 2, 3, 4, 5, 6, 7];
  const wasteIndexes = [1, 2, 3, 4, 5, 6, 7];
  const sectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <Wrapper id={id}>
      <Title>{t('expertises.compliance.title')}</Title>
      <Slogan>{t('expertises.compliance.text')}</Slogan>
      <Subwrapper>
        <List className="firstItem">
          <Subtitle>{t('expertises.compliance.material.0')}</Subtitle>
          <ul>
            {materialIndexes.map((index, i) => (
              <ListItem key={i}>{t(`expertises.compliance.material.${index}`)}</ListItem>
            ))}
          </ul>
        </List>
        <List>
          <Subtitle>{t('expertises.compliance.waste.0')}</Subtitle>
          <ul>
            {wasteIndexes.map((index, i) => (
              <ListItem key={i}>{t(`expertises.compliance.waste.${index}`)}</ListItem>
            ))}
          </ul>
        </List>
      </Subwrapper>
    </Wrapper>
  );
}

export default Compliance;
