import styled from '@emotion/styled';
import { theme } from '../../config/theme';

export const Wrapper = styled.div`
  background-color: ${theme.colour.grey};
  min-height: 100vh;
  width: fit-content;
  padding: 120px 40px 60px 40px;
  position: relative;

  ${theme.device.desktop} {
    padding: 160px 40px 60px 40px;
  }
`;

export const Title = styled.div`
  color: ${theme.colour.purple};
  font-size: 40px;
  font-weight: bold;
  text-transform: uppercase;
`;

export const Subtitle = styled.div`
  color: ${theme.colour.purple};
  font-size: 24px;
  font-weight: bold;
  margin: 10px 0 20px 0;
`;

export const ItemTitle = styled.div`
  color: ${theme.colour.pink};
  font-size: 20px;
  font-weight: bold;
  margin: 40px 0 10px 0;
`;

export const ItemText = styled.p`
  color: ${theme.colour.purple};
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 12px;
`;
