import ContactForm from '../../components/Contact/ContactForm';

function Contact() {
  return (
    <>
      <ContactForm />
    </>
  );
}

export default Contact;
