import styled from '@emotion/styled';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { theme } from '../../config/theme';

export const Container = styled(Dialog)`
  & .MuiPaper-root {
    background: ${theme.colour.darkGrey};
    border-radius: 10px;
    bottom: 0;
    box-shadow: 10px 10px 24px rgba(0, 0, 0, 0.05);
    color: ${theme.colour.purple};
    max-width: 440px;
    padding: 20px;
    position: absolute;
    right: 0;
  }
`;

export const Title = styled.p`
  font-weight: bold;
  padding: 10px;
`;

export const Text = styled(DialogContent)`
  line-height: 24px;
  padding: 5px 10px;
`;

export const Link = styled(NavLink)`
  color: ${theme.colour.blue};
  font-weight: bold;
  transition: color 300ms;

  :hover {
    color: ${theme.colour.pink};
  }
`;

export const BottomBar = styled(DialogActions)`
  display: flex;
  justify-content: end;
  margin-top: 10px;
`;

export const Button = styled.button`
  background-color: ${theme.colour.purple};
  border: 1px solid ${theme.colour.purple};
  color: ${theme.colour.white};
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  height: 44px;
  padding: 0 12px;
  text-transform: uppercase;
  transition: 300ms;
  width: fit-content;

  :hover {
    background-color: ${theme.colour.pink};
    border: 1px solid ${theme.colour.pink};
    transition: 300ms;
  }

  &.reject {
    background-color: ${theme.colour.darkGrey};
    border: 1px solid ${theme.colour.purple};
    color: ${theme.colour.purple};
    margin-right: 20px;

    :hover {
      background-color: ${theme.colour.darkGrey};
      color: ${theme.colour.purple};
    }
  }
`;
