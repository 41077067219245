import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import { theme } from '../../config/theme';
import { ReactComponent as MaterLogo } from '../../static/assets/icons/logo_light.svg';

export const Wrapper = styled.ul`
  background-color: ${theme.colour.purple};
  display: flex;
  height: 80px;
  justify-content: space-between;
  padding: 20px;
  position: fixed;
  width: 100vw;
  z-index: 4;

  ${theme.device.desktop} {
    height: 120px;
    padding: 20px 40px;
  }
`;

export const MobileWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const LogoWrapper = styled.div`
  flex-grow: 1;
  margin-right: 32px;
  margin-top: 2px;
  text-align: center;

  ${theme.device.desktop} {
    flex-grow: 0;
    margin-left: 0;
    margin-top: 0;
  }
`;

export const Logo = styled(MaterLogo)`
  height: 50px;

  ${theme.device.desktop} {
    height: 60px;
    margin-top: 10px;
  }
`;

export const Links = styled.div`
  display: flex;
  margin: 30px 0;
`;

export const NavbarItem = styled.div`
  font-size: 22px;
  margin-right: 30px;
  text-decoration: none;
`;

export const NavbarLink = styled(NavLink)`
  color: #fff;
  padding-right: 10px;
  text-decoration: none;
  text-transform: uppercase;
  transition: 300ms;

  :hover {
    color: ${theme.colour.pink};
  }

  /* &.active {
    color: blue;
  } */
`;

export const LanguageSwitcher = styled.li`
  color: rgba(255, 255, 255, 0.6);
  display: flex;
  font-size: 22px;
  text-decoration: none;

  div {
    cursor: pointer;
    transition: 300ms;

    :first-of-type {
      margin-right: 8px;
    }

    :hover {
      color: ${theme.colour.white};
      transition: 300ms;
    }
  }
`;
