import styled from '@emotion/styled';
import { Link as RouterLink } from 'react-router-dom';
import { theme } from '../../config/theme';

export const Wrapper = styled.div`
  background-color: ${theme.colour.purple};
  color: ${theme.colour.white};
  display: flex;
  flex-direction: column;
  height: 400px;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
`;

export const Spaghetti = styled.img`
  bottom: 0;
  left: -40px;
  position: absolute;
  z-index: 1;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 40px;
  position: relative;
  z-index: 2;
`;

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
`;

export const Category = styled.div`
  margin-bottom: 20px;
  opacity: 50%;
  text-transform: uppercase;
`;

export const Title = styled.div`
  font-size: 18px;
  margin-bottom: 10px;
`;

export const FooterLink = styled(RouterLink)`
  color: #fff;
  margin-bottom: 10px;
  text-decoration: none;
  transition: 300ms;

  :hover {
    color: ${theme.colour.pink};
    transition: 300ms;
  }
`;

export const Foot = styled.div`
  margin-bottom: 6px;
  margin-top: auto;
  position: relative;
  z-index: 2;

  ${theme.device.desktop} {
    margin-bottom: 0;
  }
`;

export const Divider = styled.div`
  background-color: ${theme.colour.white};
  height: 2px;
  margin: 10px 40px;
  opacity: 50%;
  position: relative;
  z-index: 2;
`;

export const FootLinks = styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding: 10px 40px 20px 40px;

  ${theme.device.desktop} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const LegalNotice = styled.div`
  display: flex;
  flex-direction: column;

  & > *:first-of-type {
    margin-right: 20px;
  }

  ${theme.device.desktop} {
    flex-direction: row;
  }
`;

export const Link = styled.a`
  color: ${theme.colour.white};
  display: inline-block;
  font-size: 16px;
  line-height: 20px;
  position: relative;
  text-decoration: none;
  width: fit-content;

  :after {
    background-color: ${theme.colour.pink};
    bottom: 0;
    content: '';
    height: 2px;
    left: 0;
    position: absolute;
    transform-origin: bottom right;
    transform: scaleX(0);
    transition: transform 0.25s ease-out;
    width: 100%;
  }

  :hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`;
