import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper, Title, Slogan, Subwrapper, List, Subtitle, ListItem } from './Corporate.styled';

interface CorporateProps {
  id: string;
}

function Corporate({ id }: CorporateProps) {
  const { t } = useTranslation('lang');
  const structuringIndexes = [1, 2, 3, 4, 5, 6];
  const developmentIndexes = [1, 2, 3, 4, 5, 6];
  const sectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <Wrapper id={id}>
      <Title>{t('expertises.corporate.title')}</Title>
      <Slogan>{t('expertises.corporate.text')}</Slogan>
      <Subwrapper>
        <List className="firstItem">
          <Subtitle>{t('expertises.corporate.structuring.0')}</Subtitle>
          <ul>
            {structuringIndexes.map((index, i) => (
              <ListItem key={i}>{t(`expertises.corporate.structuring.${index}`)}</ListItem>
            ))}
          </ul>
        </List>
        <List>
          <Subtitle>{t('expertises.corporate.development.0')}</Subtitle>
          <ul>
            {developmentIndexes.map((index, i) => (
              <ListItem key={i}>{t(`expertises.corporate.development.${index}`)}</ListItem>
            ))}
          </ul>
        </List>
      </Subwrapper>
    </Wrapper>
  );
}

export default Corporate;
