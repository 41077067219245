import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper, Title, Slogan, Subwrapper, List, Subtitle, ListItem } from './Litigation.styled';

interface LitigationProps {
  id: string;
}

function Litigation({ id }: LitigationProps) {
  const { t } = useTranslation('lang');
  const riskIndexes = [1, 2];
  const preIndexes = [1, 2];
  const litigationIndexes = [1, 2];
  const sectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <Wrapper id={id}>
      <Title>{t('expertises.litigation.title')}</Title>
      <Slogan>{t('expertises.litigation.text')}</Slogan>
      <Subwrapper>
        <List className="firstItem">
          <Subtitle>{t('expertises.litigation.risk.0')}</Subtitle>
          <ul>
            {riskIndexes.map((index, i) => (
              <ListItem key={i}>{t(`expertises.litigation.risk.${index}`)}</ListItem>
            ))}
          </ul>
        </List>
        <List className="firstItem">
          <Subtitle>{t('expertises.litigation.pre-litigation.0')}</Subtitle>
          <ul>
            {preIndexes.map((index, i) => (
              <ListItem key={i}>{t(`expertises.litigation.pre-litigation.${index}`)}</ListItem>
            ))}
          </ul>
        </List>
        <List>
          <Subtitle>{t('expertises.litigation.litigation.0')}</Subtitle>
          <ul>
            {litigationIndexes.map((index, i) => (
              <ListItem key={i}>{t(`expertises.litigation.litigation.${index}`)}</ListItem>
            ))}
          </ul>
        </List>
      </Subwrapper>
    </Wrapper>
  );
}

export default Litigation;
