import { useTranslation, Trans } from 'react-i18next';
import { Wrapper, LeftBlock, Title, TextBlock, Text, Spaghetti } from './Manifesto.styled';
import spaghetti from '../../../static/assets/icons/spaghetti_1.svg';

function Manifesto() {
  const { t } = useTranslation('lang');

  return (
    <Wrapper>
      <LeftBlock>
        <Trans>
          <Title>{t('home.manifesto.title')}</Title>
          <TextBlock>
            <Text>{t('home.manifesto.text._0')}</Text>
            <Text>{t('home.manifesto.text._1')}</Text>
          </TextBlock>
        </Trans>
      </LeftBlock>
      <div>
        <Spaghetti src={spaghetti} alt="Colourful curvy figure" />
      </div>
    </Wrapper>
  );
}

export default Manifesto;
