import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { theme } from '../../../config/theme';

export const Wrapper = styled.div`
  background-color: ${theme.colour.grey};
  min-height: 100vh;
  margin: 0 auto;
  padding: 40px;
  position: relative;
  width: fit-content;

  ${theme.device.desktop} {
    justify-content: space-between;
    padding: 120px 40px 80px 40px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${theme.device.tablet} {
    flex-direction: row;
    justify-content: space-between;
    width: 600px;
  }

  ${theme.device.tablet} {
    width: 680px;
  }

  ${theme.device.desktop} {
    width: 800px;
  }
`;

export const Slogan = styled.div`
  color: ${theme.colour.purple};
  font-family: 'Lato';
  font-size: 30px;
  font-style: italic;
  margin: 80px 0 40px 0;

  ${theme.device.tablet} {
    width: 680px;
  }

  ${theme.device.desktop} {
    font-size: 30px;
    margin: 40px 0;
    width: 800px;
  }
`;

export const ProfileLink = styled(Link)`
  text-decoration: none;

  &:first-of-type {
    margin-bottom: 40px;
  }

  ${theme.device.desktop} {
    &:first-of-type {
      margin-bottom: 0;
    }
  }
`;

export const ImageContainer = styled.div`
  height: 300px;
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;
  transition: opacity 300ms;

  :hover {
    opacity: 80%;
  }

  img {
    height: auto;
    overflow: hidden;
    position: absolute;
    width: -webkit-fill-available;

    &.juliette {
      top: -50px;
    }

    ${theme.device.desktop} {
      &.alice {
        left: -60px;
      }
    }

    ${theme.device.tablet} {
      height: 440px;
      width: auto;

      &.alice {
        width: auto;
      }

      &.juliette {
        left: -10px;
        top: -10px;
      }
    }
  }

  ${theme.device.tablet} {
    height: 400px;
    width: 300px;
  }
`;

export const FirstName = styled.div`
  color: ${theme.colour.purple};
  font-size: 24px;
  text-transform: uppercase;
`;

export const LastName = styled.div`
  color: ${theme.colour.purple};
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 4px;
  text-transform: uppercase;
`;

export const Subtitle = styled.div`
  color: ${theme.colour.purple};
  font-size: 16px;
  line-height: 22px;
`;
