import 'react-slideshow-image/dist/styles.css';
import { theme } from './config/theme';
import { injectGlobal } from '@emotion/css';
// Fonts
import ArticulatBold from './static/assets/fonts/ArticulatCF-Bold.woff';
import ArticulatBold2 from './static/assets/fonts/ArticulatCF-Bold.woff2';
import ArticulatDemiBold from './static/assets/fonts/ArticulatCF-DemiBold.woff';
import ArticulatDemiBold2 from './static/assets/fonts/ArticulatCF-DemiBold.woff2';
import ArticulatExtraBold from './static/assets/fonts/ArticulatCF-ExtraBold.woff';
import ArticulatExtraBold2 from './static/assets/fonts/ArticulatCF-ExtraBold.woff2';
import ArticulatExtraLight from './static/assets/fonts/ArticulatCF-ExtraLight.woff';
import ArticulatExtraLight2 from './static/assets/fonts/ArticulatCF-ExtraLight.woff2';
import ArticulatHeavy from './static/assets/fonts/ArticulatCF-Heavy.woff';
import ArticulatHeavy2 from './static/assets/fonts/ArticulatCF-Heavy.woff2';
import ArticulatLight from './static/assets/fonts/ArticulatCF-Light.woff';
import ArticulatLight2 from './static/assets/fonts/ArticulatCF-Light.woff2';
import ArticulatMedium from './static/assets/fonts/ArticulatCF-Medium.woff';
import ArticulatMedium2 from './static/assets/fonts/ArticulatCF-Medium.woff2';
import ArticulatRegular from './static/assets/fonts/ArticulatCF-Regular.woff';
import ArticulatRegular2 from './static/assets/fonts/ArticulatCF-Regular.woff2';
import ArticulatThin from './static/assets/fonts/ArticulatCF-Thin.woff';
import ArticulatThin2 from './static/assets/fonts/ArticulatCF-Thin.woff2';

injectGlobal`
  * {
    box-sizing: border-box;
    font-family: 'Articulat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    margin: 0;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  #root, html {
    background-color: ${theme.colour.grey};
    color: ${theme.colour.black};
  }
  html, body {
    scroll-behavior: smooth;
  }
  @font-face {
    font-family: 'Articulat';
    font-style: normal;
    font-weight: 100;
    src: local('ArticulatThin'),
      url(${ArticulatThin2}) format('woff2'),
      url(${ArticulatThin}) format('woff');
  }
  @font-face {
    font-family: 'Articulat';
    font-style: normal;
    font-weight: 200;
    src: local('ArticulatExtraLight'),
      url(${ArticulatExtraLight2}) format('woff2'),
      url(${ArticulatExtraLight}) format('woff');
  }
  @font-face {
    font-family: 'Articulat';
    font-style: normal;
    font-weight: 300;
    src: local('ArticulatLight'),
      url(${ArticulatLight2}) format('woff2'),
      url(${ArticulatLight}) format('woff');
  }
  @font-face {
    font-family: 'Articulat';
    font-style: normal;
    font-weight: normal;
    src: local('Articulat'),
      url(${ArticulatRegular2}) format('woff2'),
      url(${ArticulatRegular}) format('woff');
  }
  @font-face {
    font-family: 'Articulat';
    font-style: normal;
    font-weight: 500;
    src: local('ArticulatMedium'),
      url(${ArticulatMedium2}) format('woff2'),
      url(${ArticulatMedium}) format('woff');
  }
  @font-face {
    font-family: 'Articulat';
    font-style: normal;
    font-weight: 600;
    src: local('ArticulatDemiBold'),
      url(${ArticulatDemiBold2}) format('woff2'),
      url(${ArticulatDemiBold}) format('woff');
  }
  @font-face {
    font-family: 'Articulat';
    font-style: normal;
    font-weight: bold;
    src: local('ArticulatBold'),
      url(${ArticulatBold2}) format('woff2'),
      url(${ArticulatBold}) format('woff');
  }
  @font-face {
    font-family: 'Articulat';
    font-style: normal;
    font-weight: 800;
    src: local('ArticulatExtraBold'),
      url(${ArticulatExtraBold2}) format('woff2'),
      url(${ArticulatExtraBold}) format('woff');
  }
  @font-face {
    font-family: 'Articulat';
    font-style: normal;
    font-weight: 900;
    src: local('ArticulatHeavy'),
      url(${ArticulatHeavy2}) format('woff2'),
      url(${ArticulatHeavy}) format('woff');
  }
`;
