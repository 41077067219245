import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../../middlewares/context/config';
import useCheckDevice from '../../hooks/useCheckDevice/useCheckDevice';
import BurgerMenu from '../BurgerMenu/BurgerMenu';

import {
  Wrapper,
  MobileWrapper,
  LogoWrapper,
  Logo,
  Links,
  NavbarItem,
  NavbarLink,
  LanguageSwitcher
} from './Navbar.styled';

function Navbar() {
  const { t, i18n } = useTranslation('lang');
  const { update } = useContext(ConfigContext);
  const { isMobile, isTablet } = useCheckDevice();
  const [isVisible, setIsVisible] = useState(false);

  const changeLanguage = (lang: string) => () => {
    i18n.changeLanguage(lang);
    update((state) => ({ ...state, userLanguage: lang.substring(0, 2) }));
    localStorage.setItem('userLanguage', JSON.stringify(lang));
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <Wrapper>
      {isMobile || isTablet ? (
        <MobileWrapper>
          <BurgerMenu isVisible={isVisible} toggleVisibility={toggleVisibility} />
          <LogoWrapper>
            <NavbarLink to="/" onClick={() => window.scrollTo(0, 0)}>
              <Logo />
            </NavbarLink>
          </LogoWrapper>
        </MobileWrapper>
      ) : (
        <>
          <LogoWrapper>
            <NavbarLink to="/" onClick={() => window.scrollTo(0, 0)}>
              <Logo />
            </NavbarLink>
          </LogoWrapper>
          <Links>
            <NavbarItem>
              <NavbarLink to="/" onClick={() => window.scrollTo(0, 0)}>
                {t('navbar.home')}
              </NavbarLink>
            </NavbarItem>
            <NavbarItem>
              <NavbarLink to="/expertises" onClick={() => window.scrollTo(0, 0)}>
                {t('navbar.expertises')}
              </NavbarLink>
            </NavbarItem>
            <NavbarItem>
              <NavbarLink to="/team" onClick={() => window.scrollTo(0, 0)}>
                {t('navbar.team')}
              </NavbarLink>
            </NavbarItem>
            <NavbarItem>
              <NavbarLink to="/contact" onClick={() => window.scrollTo(0, 0)}>
                {t('navbar.contact')}
              </NavbarLink>
            </NavbarItem>
            <LanguageSwitcher>
              <div onClick={changeLanguage('en')}>EN</div>
              <div onClick={changeLanguage('fr')}>FR</div>
            </LanguageSwitcher>
          </Links>
        </>
      )}
    </Wrapper>
  );
}

export default Navbar;
