import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { theme } from '../../config/theme';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  animation: ${spin} 1s linear infinite;
  background: ${theme.colour.pink};
  border-radius: 50%;
  height: 44px;
  position: relative;
  width: 44px;

  ::before,
  ::after {
    content: '';
    position: absolute;
  }

  ::before {
    background: linear-gradient(0deg, hsla(0, 0%, 100%, 1) 50%, hsla(0, 0%, 100%, 0.9) 100%) 0% 0%,
      linear-gradient(90deg, hsla(0, 0%, 100%, 0.9) 0%, hsla(0, 0%, 100%, 0.6) 100%) 100% 0%,
      linear-gradient(180deg, hsla(0, 0%, 100%, 0.6) 0%, hsla(0, 0%, 100%, 0.3) 100%) 100% 100%,
      linear-gradient(360deg, hsla(0, 0%, 100%, 0.3) 0%, hsla(0, 0%, 100%, 0) 100%) 0% 100%;
    background-repeat: no-repeat;
    background-size: 50% 50%;
    border-radius: 50%;
    bottom: -1px;
    left: -1px;
    right: -1px;
    top: -1px;
  }

  ::after {
    background: ${theme.colour.grey};
    border-radius: 50%;
    bottom: 5px;
    left: 5px;
    right: 5px;
    top: 5px;
  }
`;
