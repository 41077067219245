import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Wrapper,
  Title,
  Slogan,
  Subwrapper,
  Container,
  Box,
  Subtitle,
  Button
} from './Hero.styled';

function Hero() {
  const { t } = useTranslation('lang');
  const navigate = useNavigate();
  const subtitleIndexes = [0, 1, 2];

  const handleButtonClick = (index: number) => {
    if (index === 0) {
      navigate('/expertises#corporate');
    } else if (index === 1) {
      navigate('/expertises#compliance');
    } else {
      navigate('/expertises#litigation');
    }
  };

  return (
    <Wrapper>
      <Title>{t('home.expertises.title')}</Title>
      <Slogan>{t('home.expertises.slogan')}</Slogan>
      <Subwrapper>
        <Container>
          {subtitleIndexes.map((index, i) => (
            <Box key={i}>
              <Subtitle>{t(`home.expertises.subtitle.${index}`)}</Subtitle>
              <Button onClick={() => handleButtonClick(index)}>
                {t('home.expertises.button')}
              </Button>
            </Box>
          ))}
        </Container>
      </Subwrapper>
    </Wrapper>
  );
}

export default Hero;
