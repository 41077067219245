import styled from '@emotion/styled';
import { theme } from '../../../config/theme';

export const Wrapper = styled.div`
  background-color: ${theme.colour.purple};
  display: flex;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
`;

export const LeftBlock = styled.div`
  padding: 40px;
  z-index: 3;

  ${theme.device.desktop} {
    padding: 60px 40px;
  }
`;

export const Title = styled.div`
  color: ${theme.colour.white};
  font-size: 26px;
  margin-bottom: 100px;
  max-width: 900px;

  span,
  strong {
    font-weight: bold;
  }

  ${theme.device.desktop} {
    font-size: 30px;
  }
`;

export const TextBlock = styled.div`
  ${theme.device.desktop} {
    display: flex;
    width: 100vw;
  }
`;

export const Text = styled.p`
  color: ${theme.colour.white};
  font-family: 'Lato';
  font-size: 18px;
  line-height: 20px;
  margin-top: 30px;

  ${theme.device.desktop} {
    font-size: 20px;
    line-height: 32px;
    margin-right: 100px;
    max-width: 540px;
  }
`;

export const Spaghetti = styled.img`
  bottom: -200px;
  left: -390px;
  position: absolute;
  right: 0;
  z-index: 2;
  width: 330%;

  ${theme.device.desktop} {
    bottom: -600px;
    left: 40px;
    position: absolute;
    right: 0;
    z-index: 2;
    width: 140%;
  }
`;
