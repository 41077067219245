import styled from '@emotion/styled';
import { theme } from '../../../config/theme';

export const Wrapper = styled.div`
  background-color: ${theme.colour.grey};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 40px;
  position: relative;

  ::before {
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100vw;
    background: url('/static/images/concrete.png') no-repeat center center scroll;
    background-size: cover;
  }
`;

export const Title = styled.div`
  color: ${theme.colour.purple};
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 20px;
  text-transform: uppercase;
  z-index: 1;

  ${theme.device.desktop} {
    margin-bottom: 0;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;

  ${theme.device.desktop} {
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    width: 1000px;
  }
`;

export const Box = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  color: ${theme.colour.purple};
  margin-bottom: 10px;
  margin-top: 20px;
  overflow: hidden;
  padding: 30px;
  position: relative;
  transition: background-color 300ms;

  &:first-of-type {
    margin-top: 10px;
  }

  :hover {
    background-color: rgba(255, 255, 255, 1);
  }

  ${theme.device.desktop} {
    height: 400px;
    margin-bottom: 10px;
    margin-top: 0;
    padding: 20px;
    width: 300px;

    &:first-of-type {
      margin-top: 0;
    }
  }
`;

export const Divider = styled.div`
  background-color: ${theme.colour.pink};
  height: 2px;
  margin-bottom: 20px;
  margin-top: 5px;
  width: 80px;
`;

export const Subtitle = styled.div`
  color: ${theme.colour.purple};
  font-family: 'Lato';
  font-size: 24px;
`;

export const Text = styled.div`
  font-family: 'Lato';
  font-size: 20px;
  line-height: 26px;
`;
