import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useCheckDevice from '../../hooks/useCheckDevice/useCheckDevice';
import Hero from '../../components/Expertise/Hero/Hero';
import Corporate from '../../components/Expertise/Corporate/Corporate';
import Compliance from '../../components/Expertise/Compliance/Compliance';
import Litigation from '../../components/Expertise/Litigation/Litigation';
import { Wrapper } from './Expertises.styled';

function Expertise() {
  const location = useLocation();
  const { isDesktop, isXldesktop } = useCheckDevice();
  const marginTop = isDesktop || isXldesktop ? 120 : 80;

  useEffect(() => {
    const hash = location.hash.slice(1);
    const element = document.getElementById(hash);
    console.log('isDesktop? ' + isDesktop + isXldesktop);

    if (element) {
      window.scrollTo({
        top: element.offsetTop - marginTop,
        behavior: 'smooth'
      });
    }
  }, [location, marginTop]);

  return (
    <Wrapper>
      <Hero />
      <Corporate id="corporate" />
      <Compliance id="compliance" />
      <Litigation id="litigation" />
    </Wrapper>
  );
}

export default Expertise;
