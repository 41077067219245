import styled from '@emotion/styled';
import { theme } from '../../config/theme';
import { KeyboardArrowDown } from '@mui/icons-material';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Button = styled.button`
  border: none;
  cursor: pointer;
  display: flex;
  min-height: 20px;
  padding: 10px 20px;
`;

export const Title = styled.div`
  color: ${theme.colour.purple};
  font-family: 'Lato';
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  text-transform: uppercase;
`;

export const Chevron = styled(KeyboardArrowDown)`
  color: ${theme.colour.purple};
  margin-left: auto;
  transition: transform 0.1s ease;

  &.rotate {
    transform: rotate(180deg);
  }
`;

export const Body = styled.div`
  overflow: hidden;
  transition: height 0.1s ease-out;
`;

export const Content = styled.div`
  color: ${theme.colour.purple};
  font-family: 'Lato';
  padding: 20px;
`;
