import { useTranslation } from 'react-i18next';
import { Wrapper, Title, Subtitle, ItemTitle, ItemText } from './LegalNotice.styled';

function LegalNotice() {
  const { t } = useTranslation('lang');
  const titleIndexes = Object.keys(t('legal-notice.items.title', { returnObjects: true }));

  return (
    <Wrapper>
      <Title>{t('legal-notice.title')}</Title>
      <Subtitle>{t('legal-notice.subtitle')}</Subtitle>
      {titleIndexes.map((index) => {
        const textKeys = Object.keys(
          t(`legal-notice.items.text.${index}`, { returnObjects: true })
        );
        return (
          <div key={index}>
            <ItemTitle>{t(`legal-notice.items.title.${index}`)}</ItemTitle>
            {textKeys.map((textKey, i) => (
              <ItemText key={i}>{t(`legal-notice.items.text.${index}.${textKey}`)}</ItemText>
            ))}
          </div>
        );
      })}
    </Wrapper>
  );
}

export default LegalNotice;
