import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../../middlewares/context/config';
import { Burger, Bar, Menu, MenuItem, MenuLink, LanguageSwitcher } from './BurgerMenu.styled';

type BurgerMenuProps = {
  isVisible: boolean;
  toggleVisibility: () => void;
};

function BurgerMenu({ isVisible, toggleVisibility }: BurgerMenuProps) {
  const { t, i18n } = useTranslation('lang');
  const { update } = useContext(ConfigContext);

  const changeLanguage = (lang: string) => () => {
    i18n.changeLanguage(lang);
    update((state) => ({ ...state, userLanguage: lang.substring(0, 2) }));
    localStorage.setItem('userLanguage', JSON.stringify(lang));
  };

  const handleClick = () => {
    toggleVisibility();
  };

  return (
    <Burger className="burgerMenu">
      <div onClick={handleClick}>
        <Bar />
        <Bar />
        <Bar />
      </div>
      <Menu isVisible={isVisible}>
        <MenuItem>
          <MenuLink href="/">{t('navbar.home')}</MenuLink>
        </MenuItem>
        <MenuItem>
          <MenuLink href="/expertises">{t('navbar.expertises')}</MenuLink>
        </MenuItem>
        <MenuItem>
          <MenuLink href="/team">{t('navbar.team')}</MenuLink>
        </MenuItem>
        <MenuItem>
          <MenuLink href="/contact">{t('navbar.contact')}</MenuLink>
        </MenuItem>
        <LanguageSwitcher>
          <div onClick={changeLanguage('en')}>EN</div>
          <div onClick={changeLanguage('fr')}>FR</div>
        </LanguageSwitcher>
      </Menu>
    </Burger>
  );
}

export default BurgerMenu;
