import { useTranslation, Trans } from 'react-i18next';
import Collapsible from '../../Collapsible/Collapsible';
import {
  Wrapper,
  LeftContainer,
  RightContainer,
  FirstName,
  LastName,
  ProfileWrapper,
  Image,
  Link,
  VerticalText,
  Description,
  Text
} from './Juliette.styled';

function Juliette() {
  const { t } = useTranslation('lang');
  const descriptionIndexes = [0, 1, 2];
  const experienceIndexes = [1, 2, 3, 4, 5, 6];
  const expertiseIndexes = [1, 2, 3, 4, 5, 6, 7];
  const educationIndexes = [1, 2, 3, 4, 5, 6];
  const languagesIndexes = [1, 2];

  return (
    <Wrapper>
      <LeftContainer>
        <FirstName>Juliette</FirstName>
        <LastName>Roquette</LastName>
        <ProfileWrapper>
          <Image>
            <img
              className="juliette"
              src={'/static/images/juliette_roquette.png'}
              alt="Juliette Roquette"
            />
          </Image>
          <VerticalText>{t('team.profiles.juliette.title')}</VerticalText>
        </ProfileWrapper>
        <Link href="mailto:juliette.roquette@mater.law" target="_blank" rel="noreferrer">
          juliette.roquette@mater.law
        </Link>
        <Link
          href="https://www.linkedin.com/in/juliette-roquette-51389917b"
          target="_blank"
          rel="noreferrer"
        >
          LinkedIn
        </Link>
      </LeftContainer>
      <RightContainer>
        <Description>
          {descriptionIndexes.map((index, i) => (
            <Text key={i}>{t(`team.profiles.juliette.description.${index}`)}</Text>
          ))}
        </Description>
        <Collapsible title={t('team.profiles.juliette.collapsible.experience.0')}>
          <ul>
            <Trans>
              {experienceIndexes.map((index, i) => (
                <li key={i}>{t(`team.profiles.juliette.collapsible.experience.${index}`)}</li>
              ))}
            </Trans>
          </ul>
        </Collapsible>
        <Collapsible title={t('team.profiles.juliette.collapsible.expertises.0')}>
          <ul>
            {expertiseIndexes.map((index, i) => (
              <li key={i}>{t(`team.profiles.juliette.collapsible.expertises.${index}`)}</li>
            ))}
          </ul>
        </Collapsible>
        <Collapsible title={t('team.profiles.juliette.collapsible.education.0')}>
          <ul>
            <Trans>
              {educationIndexes.map((index, i) => (
                <li key={i}>{t(`team.profiles.juliette.collapsible.education.${index}`)}</li>
              ))}
            </Trans>
          </ul>
        </Collapsible>
        <Collapsible title={t('team.profiles.juliette.collapsible.languages.0')}>
          <ul>
            {languagesIndexes.map((index, i) => (
              <li key={i}>{t(`team.profiles.juliette.collapsible.languages.${index}`)}</li>
            ))}
          </ul>
        </Collapsible>
      </RightContainer>
    </Wrapper>
  );
}

export default Juliette;
