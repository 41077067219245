/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { send } from 'emailjs-com';
import Loader from '../Loader/Loader';
import {
  Wrapper,
  Form,
  Title,
  Subtitle,
  TextInput,
  TextArea,
  Label,
  Checkbox,
  ButtonWrapper,
  Button,
  ButtonMessage,
  Applications,
  Text,
  Link
} from './ContactForm.styled';

function Contact() {
  const { t } = useTranslation('lang');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [formError, setFormError] = useState(false);
  const [formData, setFormData] = useState({
    from_name: '',
    reply_to: '',
    message: '',
    company: ''
  });

  const onSubmit = (e: any) => {
    e.preventDefault();
    setIsSubmitted(true);
    send('service_sx8kacs', 'template_hvmnkik', formData, 'UCnxel54QxPyY8hvv')
      .then(() => {
        setIsSubmitted(false);
        setIsSuccessful(true);
      })
      .catch(() => {
        setFormError(true);
      });
  };

  const handleFormDataChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <Wrapper>
      <Form onSubmit={onSubmit}>
        <Title>{t('contact.form.title')}</Title>
        <Subtitle>{t('contact.form.subtitle')}</Subtitle>
        {!isSuccessful ? (
          <>
            <TextInput
              type="text"
              name="from_name"
              placeholder={t('contact.form.name')}
              value={formData.from_name}
              onChange={handleFormDataChange}
              required
            />
            <TextInput
              type="text"
              name="reply_to"
              placeholder={t('contact.form.email')}
              value={formData.reply_to}
              onChange={handleFormDataChange}
              required
            />
            <TextInput
              type="text"
              name="company"
              placeholder={t('contact.form.company')}
              value={formData.company}
              onChange={handleFormDataChange}
              required
            />
            <TextArea
              name="message"
              placeholder={t('contact.form.message')}
              value={formData.message}
              onChange={handleFormDataChange}
              required
            />
            <Label>
              <Checkbox type="checkbox" name="accept_conditions" required />
              <span>{t('contact.form.checkbox')}</span>
            </Label>
            <ButtonWrapper>
              <Button type="submit" disabled={isSubmitted}>
                {t('contact.form.button')}
              </Button>
              {isSubmitted && <Loader />}
            </ButtonWrapper>
          </>
        ) : (
          <Text>{t('contact.form.success')}</Text>
        )}
        {formError && <ButtonMessage>{t('contact.form.error')}</ButtonMessage>}
      </Form>
      <Applications>
        <Title>{t('contact.talents.title')}</Title>
        <Subtitle>{t('contact.talents.subtitle')}</Subtitle>
        <Text>
          {t('contact.talents.text_1')}
          <Link
            href="mailto:contact@mater.law"
            target="_blank"
            rel="noreferrer"
            className="link__contact"
          >
            contact@mater.law
          </Link>
          {t('contact.talents.text_2')}
        </Text>
      </Applications>
    </Wrapper>
  );
}

export default Contact;
