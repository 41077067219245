/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useRef, PropsWithChildren } from 'react';
import { Wrapper, Button, Title, Chevron, Body, Content } from './Collapsible.styled';

type CollapsibleTypes = PropsWithChildren<{
  title: string;
}>;

function Collapsible({ title, children }: CollapsibleTypes) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [height, setHeight] = useState<any>(0);
  const [rotate, setRotate] = useState(false);

  const contentHeight = isExpanded ? height : 0;

  const innerRef = useRef<HTMLDivElement>(null);

  const handleToggle = (e: any) => {
    e.preventDefault();
    setIsExpanded(!isExpanded);
    setRotate(!rotate);
    setHeight(innerRef.current?.clientHeight);
  };

  return (
    <Wrapper>
      <Button onClick={(e) => handleToggle(e)}>
        <Title>{title}</Title>
        <Chevron className={`${rotate ? 'rotate' : ''}`} />
      </Button>
      <Body style={{ height: contentHeight + 'px' }}>
        <Content ref={innerRef}>{children}</Content>
      </Body>
    </Wrapper>
  );
}

export default Collapsible;
