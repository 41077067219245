import { useEffect, useState } from 'react';
import { breakpoint } from '../../config/theme';

function useCheckDevice() {
  const [width, setWidth] = useState(window.innerWidth);
  const [isMobile, setMobile] = useState(false);
  const [isTablet, setTablet] = useState(false);
  const [isDesktop, setDesktop] = useState(false);
  const [isXldesktop, setXldesktop] = useState(false);
  const handleResize = () => setWidth(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (width <= breakpoint.mobile) {
      setMobile(true);
      setTablet(false);
      setDesktop(false);
      setXldesktop(false);
    } else if (width >= breakpoint.tablet && width < breakpoint.desktop) {
      setMobile(false);
      setTablet(true);
      setDesktop(false);
      setXldesktop(false);
    } else if (width >= breakpoint.desktop && width < breakpoint.xldesktop) {
      setMobile(false);
      setTablet(false);
      setDesktop(true);
      setXldesktop(false);
    } else {
      setMobile(false);
      setTablet(false);
      setDesktop(false);
      setXldesktop(true);
    }
  }, [width]);

  return { isMobile, isTablet, isDesktop, isXldesktop };
}

export default useCheckDevice;
