import styled from '@emotion/styled';
import { theme } from '../../../config/theme';

export const Wrapper = styled.div`
  min-height: 100vh;
  padding: 40px;
  position: relative;
  background: url('/static/images/concrete.png') no-repeat center center scroll;
  background-size: cover;

  ${theme.device.desktop} {
    padding: 60px 40px;
  }
`;

export const Title = styled.div`
  color: ${theme.colour.purple};
  font-size: 40px;
  font-weight: bold;
  text-transform: uppercase;
`;

export const Slogan = styled.div`
  color: ${theme.colour.purple};
  font-size: 30px;
  margin: 40px 0;
  max-width: 490px;

  span {
    font-weight: bold;
  }
`;

export const Subwrapper = styled.div`
  align-items: center;
  display: flex;
  height: 70%;
  margin: 0 auto;
  width: fit-content;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${theme.device.desktop} {
    flex-direction: row;
    justify-content: space-between;
    width: 1100px;
  }
`;

export const Box = styled.div`
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  color: ${theme.colour.purple};
  display: flex;
  flex-direction: column;
  height: 190px;
  margin-bottom: 20px;
  padding: 20px;
  position: relative;
  transition: background-color 300ms;
  width: 300px;

  :hover {
    background-color: rgba(255, 255, 255, 1);
  }

  ${theme.device.desktop} {
    height: 300px;
    margin-bottom: 10px;
    margin-top: 80px;
  }
`;

export const Subtitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin: 20px 0 45px 0;
  text-transform: uppercase;

  ${theme.device.desktop} {
    margin: 40px 0;
  }
`;

export const Button = styled.button`
  background-color: ${theme.colour.darkGrey};
  border: 1px solid ${theme.colour.purple};
  color: ${theme.colour.purple};
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  height: 44px;
  text-transform: uppercase;
  transition: 300ms;
  width: 100px;

  :hover {
    background-color: ${theme.colour.purple};
    color: ${theme.colour.white};
    transition: 300ms;
  }

  ${theme.device.desktop} {
    margin-top: 60px;
  }
`;
