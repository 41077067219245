import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as en from '../../static/translations/en.json';
import * as fr from '../../static/translations/fr.json';

const resources = {
  en: {
    lang: en
  },
  fr: {
    lang: fr
  }
};

const lng = navigator.language.substring(0, 2) === 'en' ? 'en' : 'fr';

i18next.use(initReactI18next).init({
  lng, // as default
  fallbackLng: 'fr',
  defaultNS: 'common',
  resources,
  compatibilityJSON: 'v3',
  interpolation: {
    escapeValue: false
  }
});

export default i18next;
