import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../../middlewares/context/config';
import { Container, Title, Text, Link, BottomBar, Button } from './CookiesPopin.styled';

function CookiesPopin() {
  const { t } = useTranslation('lang');
  const { state, update } = useContext(ConfigContext);

  return (
    <Container open={state.cookiesConsent === undefined}>
      <Title>{t('cookies.title')}</Title>
      <Text>{t('cookies.text.0')}</Text>
      <Text>
        {t('cookies.text.1')} <Link to="/privacy-policy">mater.law/privacy-policy</Link>.
      </Text>
      <BottomBar disableSpacing>
        <Button
          className="reject"
          onClick={() => update((state) => ({ ...state, cookiesConsent: false }))}
        >
          {t('cookies.button_reject')}
        </Button>
        <Button onClick={() => update((state) => ({ ...state, cookiesConsent: true }))}>
          {t('cookies.button_accept')}
        </Button>
      </BottomBar>
    </Container>
  );
}

export default CookiesPopin;
