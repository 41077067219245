import styled from '@emotion/styled';
import { theme } from '../../../config/theme';

export const Wrapper = styled.div`
  color: ${theme.colour.purple};
  background-color: ${theme.colour.grey};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 80px 40px 0 40px;
  position: relative;

  strong {
    font-weight: 700;
  }

  ${theme.device.desktop} {
    flex-direction: row;
    justify-content: center;
    padding: 120px 40px 0 40px;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px;
  position: relative;
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 40px 0;
  max-width: 800px;

  ul {
    line-height: 26px;
    padding-left: 20px;
  }

  ${theme.device.desktop} {
    margin: 150px 0 40px 0;
  }
`;

export const FirstName = styled.div`
  font-size: 40px;
  text-transform: uppercase;
`;

export const LastName = styled.div`
  font-size: 40px;
  font-weight: bold;
  text-transform: uppercase;
`;

export const ProfileWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  position: relative;
`;

export const Image = styled.div`
  height: 400px;
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;
  transition: opacity 300ms;
  width: 300px;

  img {
    bottom: 10px;
    left: -60px;
    height: 440px;
    overflow: hidden;
    position: absolute;
  }
`;

export const Link = styled.a`
  color: ${theme.colour.purple};
  display: inline-block;
  font-size: 18px;
  line-height: 28px;
  position: relative;
  text-decoration: none;
  width: fit-content;

  :after {
    background-color: ${theme.colour.pink};
    bottom: 0;
    content: '';
    height: 2px;
    left: 0;
    position: absolute;
    transform-origin: bottom right;
    transform: scaleX(0);
    transition: transform 0.25s ease-out;
    width: 100%;
  }

  :hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  ${theme.device.desktop} {
    font-size: 22px;
    line-height: 30px;
  }
`;

export const VerticalText = styled.div`
  color: ${theme.colour.purple};
  display: flex;
  font-size: 20px;
  justify-content: end;
  left: -176px;
  position: absolute;
  text-transform: uppercase;
  top: 134px;
  transform: rotate(270deg);
  width: 290px;
`;

export const Description = styled.div`
  margin-bottom: 20px;
`;

export const Text = styled.div`
  font-family: 'Lato';
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 10px;

  ${theme.device.desktop} {
    font-size: 18px;
  }
`;
