import styled from '@emotion/styled';
import { theme } from '../../config/theme';

export const Wrapper = styled.div`
  background-color: ${theme.colour.grey};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0 auto;
  width: fit-content;
  padding: 80px 40px 0 40px;
  position: relative;

  ${theme.device.desktop} {
    flex-direction: row;
    justify-content: space-between;
    padding: 120px 40px 40px 40px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 60px;

  ${theme.device.desktop} {
    margin-right: 60px;
    width: 440px;
  }
`;

export const Title = styled.div`
  color: ${theme.colour.purple};
  font-size: 26px;
  font-weight: 500;
`;

export const Subtitle = styled.div`
  color: ${theme.colour.pink};
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 40px;
`;

export const TextInput = styled.input`
  background-color: ${theme.colour.darkGrey};
  border: 1px solid ${theme.colour.purple};
  border-radius: 2px;
  color: ${theme.colour.purple};
  font-size: 16px;
  height: 40px;
  margin-bottom: 20px;
  padding: 10px;

  ${theme.device.desktop} {
    width: 360px;
  }
`;

export const TextArea = styled.textarea`
  background-color: ${theme.colour.darkGrey};
  border: 1px solid ${theme.colour.purple};
  border-radius: 2px;
  color: ${theme.colour.purple};
  font-size: 16px;
  height: 40px;
  margin-bottom: 20px;
  max-height: 280px;
  min-height: 40px;
  padding: 10px;

  &[name='message'] {
    height: 120px;
  }

  ${theme.device.desktop} {
    width: 360px;
  }
`;

export const Label = styled.label`
  color: ${theme.colour.purple};
  font-size: 16px;
  margin-bottom: 10px;
  max-width: 380px;
  user-select: none;
  align-items: center;

  span {
    line-height: 22px;
    margin-left: 4px;
    vertical-align: top;
  }
`;

export const Checkbox = styled.input`
  border-radius: 2px;
  height: 20px;
  margin-right: 4px;
  margin-top: 1px;
  width: 20px;

  :checked {
    accent-color: ${theme.colour.pink};
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
`;

export const Button = styled.button`
  background-color: ${theme.colour.darkGrey};
  border: 1px solid ${theme.colour.purple};
  color: ${theme.colour.purple};
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  height: 44px;
  margin-right: 20px;
  text-transform: uppercase;
  transition: 300ms;
  width: 100px;

  :hover {
    background-color: ${theme.colour.purple};
    color: ${theme.colour.white};
    transition: 300ms;
  }

  :disabled {
    background-color: ${theme.colour.purple};
    color: ${theme.colour.white};
    cursor: default;
    opacity: 20%;
  }
`;

export const ButtonMessage = styled.div`
  color: ${theme.colour.purple};
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 10px;
  max-width: 380px;
`;

export const Applications = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  margin-top: 60px;

  ${theme.device.desktop} {
    margin-bottom: 0;
  }
`;

export const Text = styled.div`
  color: ${theme.colour.purple};
  font-size: 18px;
  line-height: 26px;
  margin-top: -10px;
  max-width: 440px;
`;

export const Link = styled.a`
  color: ${theme.colour.purple};
  display: inline-block;
  font-size: 18px !important;
  font-weight: bold;
  line-height: 26px;
  margin-top: -10px;
  position: relative;
  text-decoration: none;

  &.link__contact {
    font-size: 14px;
  }

  :after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: ${theme.colour.pink};
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  :hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  ${theme.device.tablet} {
    font-size: 22px;
    line-height: 30px;

    &.link__contact {
      font-size: 18px;
    }
  }
`;
