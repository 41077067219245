import { useTranslation, Trans } from 'react-i18next';
import { Wrapper, Title, ItemTitle, ItemText } from './PrivacyPolicy.styled';

function PrivacyPolicy() {
  const { t } = useTranslation('lang');
  const titleIndexes = Object.keys(t('privacy-policy.items.title', { returnObjects: true }));
  const subtitleIndexes = Object.keys(t('privacy-policy.subtitle', { returnObjects: true }));

  return (
    <Wrapper>
      <Title>{t('privacy-policy.title')}</Title>
      {subtitleIndexes.map((index) => (
        <ItemText key={index}>{t(`privacy-policy.subtitle.${index}`)}</ItemText>
      ))}
      {titleIndexes.map((index) => {
        const textKeys = Object.keys(
          t(`privacy-policy.items.text.${index}`, { returnObjects: true })
        );
        return (
          <div key={index}>
            <ItemTitle>{t(`privacy-policy.items.title.${index}`)}</ItemTitle>
            {textKeys.map((textKey, i) => (
              <Trans key={i}>
                <ItemText>{t(`privacy-policy.items.text.${index}.${textKey}`)}</ItemText>
              </Trans>
            ))}
          </div>
        );
      })}
    </Wrapper>
  );
}

export default PrivacyPolicy;
