import Hero from '../../components/Home/Hero/Hero';
import Manifesto from '../../components/Home/Manifesto/Manifesto';
import Expertises from '../../components/Home/Expertises/Expertises';
// import Ambition from '../../components/Home/Ambition/Ambition';
import { Wrapper } from './Home.styled';

function Home() {
  return (
    <Wrapper>
      <Hero />
      <Manifesto />
      <Expertises />
      {/* <Ambition /> */}
    </Wrapper>
  );
}

export default Home;
