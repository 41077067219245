import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { ConfigContext } from './middlewares/context/config';
import CookiesPopin from './components/CookiesPopin/CookiesPopin';
import './App.styled';

import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _mtm: any[];
  }
}

function App() {
  const { i18n } = useTranslation('lang');
  const { state, update } = useContext(ConfigContext);

  useEffect(() => {
    const storedLang = localStorage.getItem('userLanguage');
    if (storedLang) {
      update((state) => ({ ...state, userLanguage: JSON.parse(storedLang) }));
      i18n.changeLanguage(storedLang);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('cookies-consent', JSON.stringify(state.cookiesConsent));
  }, [state.cookiesConsent]);

  // Matomo tag manager
  useEffect(() => {
    if (state.cookiesConsent) {
      const _mtm = (window._mtm = window._mtm || []);
      _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });
      const d = document,
        g = d.createElement('script'),
        s = d.getElementsByTagName('script')[0];
      g.async = true;
      g.src = 'https://cdn.matomo.cloud/lawmater.matomo.cloud/container_w9zA9RoO.js';
      s.parentNode?.insertBefore(g, s);
    }
  }, [state.cookiesConsent]);

  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />
      <CookiesPopin />
    </>
  );
}

export default App;
