export const breakpoint = {
  mobile: 767,
  tablet: 768,
  desktop: 1025,
  xldesktop: 1280
};

export const theme = {
  device: {
    mobile: `@media screen and (max-width: ${breakpoint.mobile}px)`,
    tablet: `@media screen and (min-width: ${breakpoint.tablet}px)`,
    desktop: `@media screen and (min-width: ${breakpoint.desktop}px)`,
    xldesktop: `@media screen and (min-width: ${breakpoint.xldesktop}px)`
  },
  colour: {
    white: '#FFFFFF',
    grey: '#F9F9F9',
    darkGrey: '#F2F2F2',
    lightBlue: '#D8E6F2',
    blue: '#04B2D9',
    pink: '#F20E4B',
    lightPurple: '#73378A',
    purple: '#3F0240',
    black: '#1B1B1B'
  }
};
