import styled from '@emotion/styled';
import { theme } from '../../../config/theme';
import image from '../../../static/assets/icons/spaghetti_3.svg';

export const Wrapper = styled.div`
  min-height: 100vh;
  padding: 40px;
  position: relative;
  background: url(${image}) no-repeat center center scroll;
  background-size: cover;

  ${theme.device.desktop} {
    padding: 60px 40px;
  }
`;

export const Title = styled.div`
  color: ${theme.colour.purple};
  font-size: 40px;
  font-weight: bold;
  text-transform: uppercase;
`;

export const Slogan = styled.div`
  color: ${theme.colour.purple};
  font-size: 30px;
  margin: 40px 0;
  max-width: 900px;
`;

export const Subwrapper = styled.div`
  margin-top: 80px;
`;

export const List = styled.div`
  margin-bottom: 40px;
  max-width: 800px;

  &.firstItem {
    margin-bottom: 80px;
  }
`;

export const Subtitle = styled.div`
  color: ${theme.colour.purple};
  font-size: 24px;
  font-weight: bold;
  margin: 0 0 20px 0;
  text-transform: uppercase;
`;

export const ListItem = styled.li`
  color: ${theme.colour.purple};
  font-size: 20px;
  line-height: 28px;
`;
