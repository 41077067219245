import styled from '@emotion/styled';
import { theme } from '../../config/theme';

export const Wrapper = styled.div`
  padding-top: 80px;

  ${theme.device.desktop} {
    padding-top: 120px;
  }
`;
